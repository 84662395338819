import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Container } from "react-bootstrap"
// import FooterNavigation from "../components/FooterNavigation"
import SocialMediaIcons from "../components/SocialMediaIcons"
import hv10Logo from "../images/hv10Logo.png"

// container wrapping the whole footer section
const FooterWrapper = styled.footer`
  position: relative;
  min-height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: ${props => props.theme.footerColor};
  background-image: radial-gradient(
    circle at 50%,
    rgba(255, 255, 255, 0.05) 10%,
    rgba(0, 0, 0, 0.5)
  );
  * {
    color: ${props => props.theme.footerTextColor};
  }
  p {
    margin: 0;
  }
  /* mobile styling */
  @media (max-width: ${props => props.theme.mobile}) {
    padding: 20px 0 20px 0;
    flex-direction: column;
    align-items: center;
    background-image: radial-gradient(
      rgba(255, 255, 255, 0.01),
      rgba(0, 0, 0, 0.5)
    );
  }
`

// container for the actual footer text
const FooterContainer = styled.div`
  display: flex;
  z-index: 2;
  justify-content: space-around;
  width: 100%;
  a {
    margin: 0 0.5rem;
  }
  /* mobile styling */
  @media (max-width: ${props => props.theme.mobile}) {
    flex-direction: column;
    margin-bottom: 0.5rem;
    align-items: center;
  }
`

const DesignerLogo = styled.img`
  height: 2rem;
  transform: translateY(-5%);
`

// elements displayed in footer navigation
const FooterNavigation = ({ items }) => {
  if (items.length === 0 || !items) {
    return
  }

  return items.map(element => (
    <Link key={element.slug.current} to={"/" + element.slug.current}>
      {element.title}
    </Link>
  ))
}

// create footer component
const Footer = ({ navigation, socialMedia, copyright, children }) => {
  return (
    <FooterWrapper>
      <FooterContainer>
        {/* display social media icons and navigation elements if they exist, else display nothing */}
        <div>
          {socialMedia ? <SocialMediaIcons accounts={socialMedia} /> : ""}
        </div>

        <div>{navigation ? <FooterNavigation items={navigation} /> : ""}</div>

        <div>
          Copyright {new Date().getFullYear()} © {copyright}
        </div>
        <div>
          Redesigned by
          <a href="https://hv10.github.io/">
            <DesignerLogo src={hv10Logo} alt="hv10" />
          </a>
        </div>
      </FooterContainer>
    </FooterWrapper>
  )
}

export default Footer
