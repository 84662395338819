// Global styling

import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`

/* hyphens (requires <html lang="de"> to work correctly) */
* {
  -moz-hyphens: auto;
   -o-hyphens: auto;
   -webkit-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto;
}

/* fonts and text stylings */
/* html + body */
html {
    overflow-x: hidden;
}
html, body {
    margin: 0;
    padding: 0;
    font-family: "Cabin", sans-serif;
    font-size: calc(1.5vmin + 7px);
    background-color: ${props => props.theme.background};
    position: relative;
    width: 100%;
    box-sizing:border-box;
  }
  *, *::after, *::before {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
  }

/* headlines */
  h1,h2,h3,h4,h5,h6{
    overflow: visible;
  }

  h1{
    font-size: 6rem;
    font-family: "Chivo", sans-serif;
    font-weight: 900;
    line-height: 0.8;
    max-width: 100vw;
    padding-left: min(5vw, 50px);
    /*overflow-wrap: break-word;*/
  }
 
  h2{
    font-size: 3rem;
    font-family: "Chivo", sans-serif;
    font-style: normal; 
    font-weight: 400; 
  }

  h3{
    font-size: 2rem;
    font-weight: 300;
  }

  h4{
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 300;
  }

/* links, p, span */
  a, p, span {
    line-height: 1.5em;
  }

/* form fields */
  input, select, textarea, label {
    font-style: normal;
    font-weight: 400;
  }

/* text link styling */
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.linkColor};
    &:hover{
      text-decoration: none;
    }
  }

/* image caption styling */
  .image-caption {
    font-size: 0.75rem;
    font-weight: 200;
    font-style: italic;
  }
`
