// Global color themes
export const colors = {
  // reference color-name.com for the names
  darkJungleGreen: "#1B2916",
  fernGreen: "#4C7043", // light green
  pomonaGreen: "#1E442F", // really dark green
  spiroDiscoBall: "#2BD8FF", // light blue
  vividSkyBlue: "#0AD2FF", // zen neon logo blue
  azure: "#007BFF", // darker light blue
  deepSaffron: "#FF9E3D", // light orange accent
  antiFlashWhite: "#f2f2f2", // white-ish text
  eerieBlack: "#1a1a1a", //dark-ish text
}

export const theme = {
  // background colors:
  primaryDark: colors.darkJungleGreen,
  primaryLight: colors.fernGreen,
  background: colors.pomonaGreen,
  lightAccent: colors.spiroDiscoBall,
  darkAccent: colors.azure,
  altAccent: colors.deepSaffron,
  lightText: colors.antiFlashWhite,
  darkText: colors.eerieBlack, // dark-ish text

  // on hover:
  primaryHover: colors.deepSaffron,
  // cadmium orange for cta button hover:

  // header:
  //headerColor: "#1B2916",
  headerHeight: "60px",

  // footer:
  footerColor: colors.darkJungleGreen,
  footerTextColor: colors.antiFlashWhite,

  // mobile width for breaking point:
  mobile: "767px",

  // Nav menu:
  // nav menu background:
  mobileMenuBackground: "rgba(97,17,17,0.9)", //<-- original version
  // mobileMenuBackground: "rgba(129, 14, 14, 0.9)", //<-- new version, not in use
  // nav menu animation:
  mobileMenuTransition: "all 0.3s linear;",
  // burger menu icon and close icon:
  toggleCloseColor: colors.vividSkyBlue,
  toggleOpenColor: colors.vividSkyBlue,
  // vivid sky blue from zen studio neon sign:
  blockCTA: colors.vividSkyBlue,

  // Nav items:
  navLinkColor: colors.antiFlashWhite,
  navItemBackground: colors.eerieBlack,
  linkActive: colors.antiFlashWhite,

  // link color of <a> elements in regular text:
  linkColor: colors.azure,
}
