// Navbar

import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
//import { theme } from "../layouts/theme"

import { MdHome } from "react-icons/md"
//import Menu from "../assets/menu.svg"

// Container for entire page to dim background when navbar is open
export const Navbar = styled.nav`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  overflow: scroll;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  left: 0;
  /* use z-index to ensure visibility */
  z-index: 99;
  top: 0px;
  background-color: ${({ open }) => (open ? "rgba(0, 0, 0, 0.5)" : "unset")};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  transition: ${({ theme }) => theme.mobileMenuTransition};
  transition: left 10ms linear;
  /* mobile styling */
  @media (max-width: ${({ theme }) => theme.mobile}) {
    position: fixed;
    overflow: scroll;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    left: 0;
    /* use z-index to ensure visibility */
    z-index: 99;
    top: 0px;
    background-color: ${({ open }) => (open ? "rgba(0, 0, 0, 0.5)" : "unset")};
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
    transition: ${({ theme }) => theme.mobileMenuTransition};
    transition: left 10ms linear;
  }
`

// Container for nav list items = actual nav menu
export const Nav = styled.ul`
  display: flex;
  position: absolute;
  left: 0;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.mobileMenuBackground};
  background-image: radial-gradient(
      farthest-side,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.8)
    ),
    linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  background-size: 40px 100%;
  background-repeat: none;
  width: 25%;
  min-height: 100%;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  transition: ${({ theme }) => theme.mobileMenuTransition};
  box-shadow: 10px 12px 20px rgba(0, 0, 0, 0.8);
  /* mobile styling */
  @media (max-width: ${({ theme }) => theme.mobile}) {
    position: absolute;
    left: 0;
    flex-direction: column;
    background-color: ${({ theme }) => theme.mobileMenuBackground};
    width: 70%;
    min-height: 100%;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
    transition: ${({ theme }) => theme.mobileMenuTransition};
  }
`

// list item - a complete row in the nav bar
const ListItem = styled.li`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  /* on hover */
  &:hover {
    background-color: ${({ theme }) => theme.navItemBackground};
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
    font-weight: 600;
  }
  /* when clicked (not in use) */
  &.active {
    border-bottom: 3px solid ${({ theme }) => theme.linkActive};
  }
  /* mobile styling */
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    min-height: 60px;
    justify-content: flex-start;
    &:first-child {
      border: 0px;
    }
  }
`
// item to be linked - the actual text within the list item
const LinkItem = styled(Link)`
  width: 100%;
  display: flex;
  padding: 0.5em 0.5em 0.5em 2em;
  vertical-align: center;
  color: ${({ theme }) => theme.navLinkColor};
  font-weight: 400;
  /* on hover */
  &:hover {
    color: ${({ theme }) => theme.navLinkColor};
    font-weight: 600;
    text-decoration: none;
  }
  /* mobile styling */ 
  @media (max-width: ${({ theme }) => theme.mobile}) {
    ${"" /* margin: 0 auto; */}
    }
  }
`

// wrapper for the burger menu icon
const ToggleWrapper = styled.div`
  display: flex;
  width: 25px;
  height: 25px;
  position: absolute;
  /* use z-index to ensure visibility */
  z-index: 100;
  top: 0.8em;
  display: flex;
  border-radius: 0.2em;
  flex-direction: column;
  justify-content: space-around;
  transition: ${({ theme }) => theme.mobileMenuTransition};

  /* the 3 lines of the burger menu are represented by a span: */
  span {
    transition: ${({ theme }) => theme.mobileMenuTransition};
    transform-origin: 1px;
    border-color: ${({ open, theme }) =>
      open ? theme.toggleOpenColor : theme.toggleCloseColor};
    /* middle line of burger menu */
    &:nth-child(2) {
      border: ${({ open }) => (open ? "unset" : open)};
    }
    /* top line of burger menu */
    &:first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    /* bottom line of burger menu */
    &:last-child {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

// create a styled component for the 3 burger menu lines
const Line = styled.span`
  width: 100%;
  height: 0px;
  border-bottom: 2px solid;
`

// component for the navbar toggle, containing the burger menu wrapper and the 3 lines
const NavbarToggle = ({ open, onClick }) => {
  return (
    <ToggleWrapper onClick={onClick} open={open}>
      <Line />
      <Line />
      <Line />
    </ToggleWrapper>
  )
}

// component for the links in the nav bar
export const NavLink = props => {
  return (
    <ListItem style={props.style}>
      <LinkItem to={props.to}>{props.children}</LinkItem>
    </ListItem>
  )
}

// styled component for the first row of the nav bar,
// which aligns with the header and contains the home icon:
const FirstRow = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  margin-bottom: 10px;
  /* link for the home icon */
  a {
    color: #3edcff;
    /* the home icon */
    svg {
      font-size: 24px;
    }
  }
  background-color: ${props => props.theme.primaryDark};
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.8);
`
// Complete Navigation component with all elements for nav bar, burger menu, home icon
export const Navigation = ({ children, navigation }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <NavbarToggle
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
        open={menuOpen}
      />
      <Navbar
        open={menuOpen}
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
      >
        <Nav open={menuOpen}>
          <FirstRow>
            <NavLink to="/" style={{ flexBasis: "25%" }}>
              <MdHome />
            </NavLink>
            {/* <Menu style={{ flexBasis: "75%", height: "auto" }} /> */}
          </FirstRow>
          {navigation?.map(element => (
            <NavLink key={element.id} to={`/${element.slug.current}`}>
              {element.title}
            </NavLink>
          ))}
        </Nav>
      </Navbar>
    </>
  )
}
