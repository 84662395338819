import PropTypes from "prop-types" // used to validate prop types
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"
import { Navigation } from "./Navigation"
import { BrandLogo } from "../components/BrandLogo"

// container for header section
const MyHeader = styled.header`
  display: flex;
  justify-content: space-between;
  background-color: ${props =>
    props.theme.headerColor ? props.theme.headerColor : "#fff"};
  background-image: radial-gradient(
    circle at 50%,
    rgba(255, 255, 255, 0.05) 10%,
    rgba(0, 0, 0, 0.5)
  );
  height: ${props => props.theme.headerHeight};
  width: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  /* use high z-index to make header fixed */
  z-index: 99;
  opacity: ${props => (props.showHeader ? 1 : 0)};
  position: fixed;
`

// header logo
const HeaderLogo = styled(BrandLogo)`
  margin-top: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  opacity: ${props => props.opacity};
  /* mobile styling */
  @media (max-width: ${props => props.theme.mobile}) {
    svg {
      width: 100px;
      height: auto;
    }
  }
`

// create header component
const Header = ({ showLogo, showHeader, navigation }) => {
  return (
    <MyHeader showHeader={showHeader}>
      <Container>
        <Row className="justify-content-between">
          <Col></Col>
          {/* logo: */}
          <Col className="d-flex justify-content-center">
            <Link to="/">
              <HeaderLogo
                opacity={showLogo ? 1 : 0}
                width="150"
                height="auto"
                className="headerLogo"
              />
            </Link>
          </Col>
          {/* burger menu: */}
          <Col className="d-flex justify-content-end">
            {navigation?.length > 0 ? (
              <Navigation navigation={navigation} />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Container>
    </MyHeader>
  )
}

// prop type string for site title
Header.propTypes = {
  siteTitle: PropTypes.string,
}

// default prop for site title is empty string
Header.defaultProps = {
  siteTitle: ``,
}

export default Header
