/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types" // used for prop type validation
import { useStaticQuery, graphql } from "gatsby"
import styled, { ThemeProvider } from "styled-components"

import Header from "../components/header"
import Footer from "../components/Footer"

import { theme } from "./theme"
import { GlobalStyle } from "./globalstyle"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// styled component for website body
const Main = styled.main`
  min-height: 100vh;
  background-size: max(15%, 250px) 100%;
  background-color: ${props => props.theme.background};
  background-attachment: fixed;
  background-image: repeating-linear-gradient(90deg, rgba(0,0,0,0.9), rgba(0,0,0,0), rgba(0,0,0,0.9));
  }
  /* mobile styling */ 
  @media (max-width: ${props => props.theme.mobile}){
    background-size: 33% 100%;

    &:before{
      background-image: radial-gradient(circle , rgba(0,0,0,0),rgba(0,0,0,0.5));
    }
  }
`

// pattern overlay for background
const PatternOverlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  padding:${props => props.theme.headerHeight} 0;
  background-image:  url("${props => props.backgroundImage}");
  background-size: 60px;
  background-attachment: fixed; /* this ensures the background doesn't scroll with the site */
`

// layout component to show logo, header and all child components wrapped in the layout
const Layout = ({ showLogo, showHeader, children }) => {
  // use static query to get data which is not in pages folder
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      backgroundImage: file(relativePath: { eq: "structure.png" }) {
        id
        publicURL
      }

      siteSettings: sanitySiteSetting {
        favIcon {
          asset {
            url
          }
        }
        socialMedia {
          platform
          url
        }
        title
      }

      headerNav: sanityNavigation(_id: { eq: "headerNavigation" }) {
        id
        items {
          title
          redirect
          slug {
            current
          }
          id
        }
      }

      footerNav: sanityNavigation(_id: { eq: "footerNavigation" }) {
        id
        items {
          title
          redirect
          slug {
            current
          }
          id
        }
      }
    }
  `)

  // console.log("data", data)

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        {/*<Header
          siteTitle={data.site.siteMetadata.title}
          showHeader={showHeader}
          showLogo={showLogo}
          navigation={data.headerNav?.items}
        />*/}
        <Main>
          <PatternOverlay backgroundImage={data.backgroundImage.publicURL}>
            {children}
          </PatternOverlay>
        </Main>
        <Footer
          navigation={data.footerNav?.items}
          copyright={data.siteSettings?.title}
          socialMedia={data.siteSettings?.socialMedia}
        />
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
